import { Custom404Template } from 'components/template'
import { NextWrapper } from '../data-fetching-wrapper'

export default function Custom404() {
  return <Custom404Template />
}
export const getStaticProps = NextWrapper.getStaticProps(async () => {
  return {
    props: {},
    revalidate: 60 * 5,
  }
})
